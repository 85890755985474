/**
 * เรียกข้อมูลการตั้งค่าจาก server
 */

import 'regenerator-runtime/runtime'

import api from "@/services/apiService"

class SystemConfigService {

  /**
   * ดึงรายการหวย
   */
  getMarkets() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_MAIN + 'market')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      })
    })
  }

  /**
   * ดึงข้อมูลกลุ่มทั้งหมดในระบบ
   * @return {Object}
   */
  getGroups() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_MAIN + 'market/group')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }

  /**
   * ดึงข้อมูลอัตราจ่าย
   * @return {Object}
   */
  getPayRates() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_MAIN + 'market/payout')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }

  /**
   * ดึงข้อมูลระดับเอเย่นต์
   * @return {Object}
   */
  getAgentLevels() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_MAIN + 'account/level')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error)
      });
    });
  }
}

export default new SystemConfigService()
